export function useCaptcha() {
    const siteKey = (visibility: boolean) => {
        console.warn('Débito tecnico - verificar a site e a secret key do captcha');
        return visibility ? process.env.CLOUDFLARE_VISIBLE : process.env.CLOUDFLARE_INVISIBLE;
    };

    const validateCaptcha = async (token: string | null): Promise<boolean> => {
        const url = 'https://challenges.cloudflare.com/turnstile/v0/siteverify';
        const formData = new FormData();

        formData.append('secret', process.env.CLOUDFLARE_SECRET_KEY);
        formData.append('response', token);

        try {
            const result = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!result.ok) {
                throw new Error(`HTTP error! status: ${result.status}`);
            };
            
            const outcome = await result.json();
            return outcome.success ?? false;
        } catch (error) {
            console.error(error);
        };
    };

    return {
        siteKey,
        validateCaptcha,
    };
}

export default useCaptcha;
