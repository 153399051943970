/* eslint-disable */

export default {
  "About us": "Sobre nós",
  "Account": "Conta",
  "Add new address": "Adicionar novo endereço",
  "Add to compare": "Adicionar para comparar",
  "Add to Wishlist": "Adicionar à Lista de Desejos",
  "Additional Information": "Informações Adicionais",
  "All Orders": "Todos os Pedidos",
  "Allow order notifications": "Permitir notificações de pedidos",
  "Apply": "Aplicar",
  "Applied Coupon": "Cupom aplicado",
  "Attention!": "Atenção!",
  "Back to home": "Voltar para página inicial",
  "Back to homepage": "Voltar para a página inicial",
  "Billing": "Faturamento",
  "Billing address": "Endereço de faturamento",
  "Brand": "Marca",
  "Cancel": "Cancelar",
  "Cart": "Carrinho",
  "Categories": "Categorias",
  "Change": "Alterar",
  "Change password your account": "Se você deseja alterar a senha para acessar sua conta, insira as seguintes informações",
  "Clear all": "Limpar tudo",
  "Color": "Cor",
  "Commercial information": "e concordo em receber informações comerciais personalizadas da marca por e-mail",
  "Contact details updated": "Mantenha seus endereços e detalhes de contato atualizados.",
  "Contact us": "Entre em contato conosco",
  "Continue to billing": "Continuar para faturamento",
  "Continue to payment": "Continuar para pagamento",
  "Continue to shipping": "Continuar para envio",
  "Cookies Policy": "Política de Cookies",
  "Create an account": "Criar uma conta",
  "Customer Reviews": "Avaliações de Clientes",
  "Customer service": "Atendimento ao Cliente",
  "Date": "Data",
  "Billing Address": "Faturamento padrão",
  "Shipping Address": "Envio padrão",
  "Delete": "Excluir",
  "Departments": "Departamentos",
  "Description": "Descrição",
  "Details and status orders": "Verifique os detalhes e o status de seus pedidos na loja online. Você também pode cancelar seu pedido ou solicitar uma devolução.",
  "Discount": "Desconto",
  "Done": "Concluído",
  "Download": "Download",
  "Download all": "Baixar todos",
  "Edit": "Editar",
  "Email address": "Endereço de e-mail",
  "Empty": "Parece que você ainda não adicionou nenhum item ao carrinho. Comece a comprar para preenchê-lo.",
  "Empty bag": "Sacola vazia",
  "Enjoy your free account": "Aproveite esses benefícios com sua conta gratuita!",
  "Enter promo code": "Digite o código promocional",
  "Feedback": "Seu feedback é importante para nós. Deixe-nos saber o que poderíamos melhorar.",
  "Feel free to edit": "Sinta-se à vontade para editar qualquer um dos seus detalhes abaixo para que sua conta esteja sempre atualizada",
  "Filters": "Filtros",
  "Find out more": "Saiba mais",
  "First Name": "Primeiro Nome",
  "Forgot Password": "Se você não se lembra da sua senha, você pode redefini-la.",
  "Forgot Password Modal Email": "E-mail que você está usando para entrar:",
  "forgotPasswordConfirmation": "Obrigado! Se houver uma conta registrada com o e-mail {0}, você receberá uma mensagem com um link para redefinir a senha na sua caixa de entrada.",
  "Forgotten password?": "Esqueceu a senha?",
  "Go back": "Voltar",
  "Go back shopping": "Voltar às compras",
  "Go back to shop": "Voltar para compras",
  "Go to checkout": "Ir para checkout",
  "Go to cart": "Ir para o carrinho",
  "Guarantee": "Garantia",
  "Help": "Ajuda",
  "Help & FAQs": "Ajuda & Perguntas Frequentes",
  "hide": "esconder",
  "Home": "Início",
  "I agree to": "Eu concordo em",
  "I confirm subscription": "Eu confirmo a inscrição",
  "I want to create an account": "Eu quero criar uma conta",
  "Info after order": "Você pode fazer login em sua conta usando e-mail e senha definidos anteriormente. Em sua conta, você pode editar seus dados de perfil, verificar o histórico de transações, editar a assinatura do boletim informativo.",
  "Instruction1": "Cuide de mim",
  "Instruction2": "Só aqui para as instruções de cuidados?",
  "Instruction3": "Sim, pensamos assim",
  "It was not possible to request a new password, please check the entered email address.": "Não foi possível solicitar uma nova senha, verifique o endereço de e-mail inserido.",
  "Item": "Item",
  "Items": "Itens",
  "Kidswear": "Moda Infantil",
  "Last Name": "Sobrenome",
  "Let’s start now – we’ll help you": "Vamos começar agora – vamos te ajudar.",
  "Log into your account": "Faça login na sua conta",
  "Login": "Entrar",
  "login in to your account": "entrar na sua conta",
  "Looks like you haven’t added any items to the cart yet. Add products to the cart so that you receive a complete quote.": "Parece que você ainda não adicionou nenhum item ao carrinho. Adicione os produtos no carrinho, para que você receber uma cotação completa.",
  "Looks like you haven’t added any items to the Wishlist.": "Parece que você ainda não adicionou nenhum item à lista de desejos.",
  "Make an order": "Fazer um pedido",
  "Manage addresses": "Gerencie todos os endereços que você deseja (local de trabalho, endereço residencial...) Assim, você não precisará inserir o endereço manualmente a cada pedido.",
  "Manage billing addresses": "Gerencie todos os endereços de faturamento que você deseja (local de trabalho, endereço residencial...) Assim, você não precisará inserir o endereço de faturamento manualmente a cada pedido.",
  "Manage shipping addresses": "Gerencie todos os endereços de envio que você deseja (local de trabalho, endereço residencial...) Assim, você não precisará inserir o endereço de envio manualmente a cada pedido.",
  "Match it with": "Combine com",
  "Men fashion": "Moda Masculina",
  "Menu": "Menu",
  "My billing and shipping address are the same": "Meu endereço de faturamento e envio são iguais",
  "No account": "Ainda não tem uma conta?",
  "or": "ou",
  "or fill the details below": "ou preencha os detalhes abaixo",
  "Order ID": "ID do Pedido",
  "Order information": "Informações do Pedido",
  "Order No.": "Nº do Pedido:",
  "Order summary": "Resumo do Pedido",
  "Other products you might like": "Outros produtos que você pode gostar",
  "Out of stock": "Sem estoque",
  "Password": "Senha",
  "Password Changed": "Senha alterada com sucesso. Você pode voltar à página inicial e fazer login agora.",
  "Pay for order": "Pagar pelo pedido",
  "Payment": "Pagamento",
  "Payment & Delivery": "Pagamento & Entrega",
  "Payment Method": "Método de Pagamento",
  "Payment Methods": "Métodos de Pagamento",
  "Personal details": "Detalhes pessoais",
  "Please type your current password to change your email address.": "Por favor, digite sua senha atual para alterar seu endereço de e-mail.",
  "Price": "Preço",
  "Privacy": "Privacidade",
  "Privacy Policy": "Política de Privacidade",
  "Product": "Produto",
  "Product suggestions": "Sugestões de produtos",
  "Products": "Produtos",
  "Products found": "Produtos encontrados",
  "Purchase terms": "Termos de Compra",
  "Quality in the details": "Qualidade nos detalhes",
  "Quantity": "Quantidade",
  "Read all reviews": "Leia todas as avaliações",
  "Read and understand": "Li e entendi",
  "Read reviews": "Ler avaliações",
  "Register": "Registrar",
  "Register today": "Registre-se hoje",
  "Remove": "Remover",
  "Remove Address": "Remover Endereço",
  "Remove from Wishlist": "Remover da Lista de Desejos",
  "Repeat Password": "Repita a Senha",
  "Reset Password": "Redefinir Senha",
  "Review my order": "Revisar meu pedido",
  "Save": "Salvar",
  "Search": "Buscar",
  "Search for products": "Buscar produtos",
  "Search for the product": "Pesquisar pelo produto",
  "See order status": "Ver status do pedido",
  "Select": "Selecionar",
  "Send": "Enviar",
  "Settings": "Configurações",
  "Share this product": "Compartilhe este produto",
  "Shipping": "Envio",
  "Shipping address": "Endereço de envio",
  "Shipping Method": "Método de Envio",
  "Shop": "Loja",
  "Shop Now": "Compre Agora",
  "Show": "Mostrar",
  "Sign in": "Entrar",
  "Sign in to your account": "Faça login na sua conta",
  "Sign in to your account or create a new one": "Faça login na sua conta ou crie uma nova",
  "Sign in your account": "Faça login na sua conta",
  "Sign out": "Sair",
  "Sign up for our Newsletter": "Inscreva-se em nossa newsletter",
  "Sign up today for our free newsletter": "Inscreva-se hoje para nossa newsletter gratuita",
  "Similar products": "Produtos similares",
  "Sort by": "Ordenar por",
  "Start shopping": "Comece a comprar",
  "Stock": "Estoque",
  "Store": "Loja",
  "Subscribe": "Inscrever-se",
  "Subscribe today and get a coupon": "Inscreva-se hoje e ganhe um cupom",
  "Successfully added to cart": "Adicionado ao carrinho com sucesso!",
  "Sorry, there are no products in this collection": "Desculpe, não há produtos nesta coleção",
  "Sorry, there was a problem": "Desculpe, houve um problema",
  "Start typing": "Comece a digitar",
  "Stay connected": "Permaneça conectado",
  "Store Information": "Informações da Loja",
  "Subtotal": "Subtotal",
  "Take your time": "Você tem tempo para pensar",
  "Thank you for creating an account": "Obrigado por criar uma conta",
  "Thank you for registering": "Obrigado por se registrar",
  "Thank you for your feedback": "Obrigado pelo seu feedback",
  "The discount code has been applied successfully": "O código de desconto foi aplicado com sucesso",
  "The error may not be there": "O erro pode não estar lá",
  "There are no products in your cart": "Não há produtos no seu carrinho",
  "There are no products in your wishlist": "Não há produtos na sua Lista de Desejos",
  "These are your products that have been selected for you for years": "Estes são os produtos que você selecionou para você",
  "This is your products": "Este é o seu produto",
  "Timeless style": "Estilo atemporal",
  "Title": "Título",
  "To be continued": "Continua",
  "Total": "Total",
  "To unlock all features": "Para desbloquear todos os recursos",
  "True colors": "Cores verdadeiras",
  "Update": "Atualizar",
  "View": "Ver",
  "View all": "Ver todos",
  "View more": "Ver mais",
  "View more reviews": "Ver mais avaliações",
  "View your profile": "Ver seu perfil",
  "Wishlist": "Lista de Desejos",
  "Women fashion": "Moda Feminina",
  "You are using:": "Você está usando:",
  "You have added": "Você adicionou",
  "You may also like": "Você também pode gostar",
  "Your address": "Seu endereço",
  "Your billing and shipping address": "Seu endereço de faturamento e envio",
  "Your cart is empty": "Seu carrinho está vazio",
  "Your order": "Seu pedido",
  "Your orders": "Seus pedidos",
  "Your wishlist": "Sua lista de desejos",
  "Your shopping cart": "Seu carrinho de compras",
  "Vue Storefront Next": "Vue Storefront Next",
  "Search for items": "Pesquisa por itens",
  "Sort: Default": "Ordenar: Padrão",
  "Sort: Name A-Z": "Ordenar: Nome A-Z",
  "Sort: Name Z-A": "Ordenar: Nome Z-A",
  "Sort: Price from high to low": "Ordenar: Preço decrescente",
  "Sort: Price from low to high": "Ordenar: Preço crescente",
  "Change to grid view": "Mudar para grade de visualização",
  "Change to list view": "Mudar para lista de visualização",
  "Colorful summer dresses are already in store": "Vestidos coloridos de verão já estão na loja.",
  "See more": "Mais detalhes",
  "New Products": "Novos Produtos",
  "Country": "País",
  "Zip-code": "CEP",
  "Phone number": "Telefone",
  "State/Province": "Estado",
  "City": "Cidade",
  "Please select a country first": "Seleciona primeiro o país",
  "House/Apartment number": "Número",
  "Street Name": "Endereço",
  "Select shipping method": "Selecione o método de envio",
  "Search for": "Pesquise por",
  "Apply filters": "Aplicar filtros",
  // "Download": "Baixar",
  "Who we are": "Quem somos nós?",
  "Size guide": "Guia de tamanhos",
  "Your email": "E-mail",
  "Your bag is empty": "Sua lista de desejos está vazia",
  "My wishlist": "Minha lista de desejos",
  "Yes": "Sim",
  "Are you sure you would like to remove this item from the shopping cart?": "Você tem certeza que deseja remover este item do seu carrinho?",
  "Total items": "Total de itens",
  "Order Total": "Total da compra",
  "Safety": "Segurança",
  "It carefully packaged with a personal touch": "Cuidadosamente embalado com um toque pessoal",
  "Easy shipping": "Envio fácil",
  "E-mail": "E-mail",
  "You’ll receive dispatch confirmation and an arrival date": "Você receberá uma confirmação de envio e uma data de chegada",
  "Changed your mind?": "Mudou de ideia?",
  "Rest assured, we offer free returns within 30 days": "Fique tranquilo, oferecemos devoluções gratuitas em 30 dias",
  "Shipping method": "Método de envio",
  "This field is required": "Campo obrigatório",
  "Check / Money order": "Pagamento em dinheiro",
  "Total price": "Preço total",
  "Terms and conditions": "Termos e condições",
  "Amount": "Valor",
  "We can't find products matching the selection.": "Não encontramos produtos que correspondam à seleção.",
  "Show more": "Mostrar mais",
  "An error ocurred": "Um erro ocorreu",
  "Back to home page": "Voltar à página inicial",
  "Add to cart": "Adicionar ao Carrinho",
  "Add": "Adicionar",
  "{0} has been successfully removed from your cart": "Produto removido com sucesso",
  "Add a review": "Avalie o produto",
  "Add review": "Enviar Avaliação",
  "Review": "Avaliação",
  "Search results": "Resultados da Pesquisa",
  "You haven’t searched for items yet": "Você ainda não pesquisou.",
  "Page not found": "Página não Encontrada",
  "USE THE COUPON": 'USE O CUPOM ',
  "AND GET DISCOUNT ON YOUR FIRST PURCHASE": 'E GANHE DESCONTO NA SUA PRIMEIRA COMPRA',
  'COUPON COPIED': 'CUPOM COPIADO',
  "AllProductsFromCategory": "TODOS OS PRODUTOS",
  "My profile": "Meu perfil",
  "My Account": "Minha Conta",
  "Addresses details": "Meus endereços",
  "My newsletter": "Newsletter",
  "Order details": "Detalhes do pedido",
  "Order history": "Histórico de pedidos",
  "Log out": "Sair",
  "My reviews": "Meus comentários",
  "Update personal data": "Atualizar dados pessoais",
  "Update password": "Atualizar senha",
  "Current Password": "Senha atual",
  "New Password": "Nova senha",
  "Your e-mail": "Seu e-mail",
  "Personal data": "Dados pessoais",
  "Password change": "Mudar senha",
  "Use your personal data": "Uso dos seus dados pessoais:",
  "Sign Up for Newsletter": "Assine pelo boletim informativo",
  "Save changes": "Salvar mudanças",
  "Set up newsletter": "Configure o boletim informativo",
  "My orders": "Meus pedidos",
  "You currently have no orders": "Atualmente, você não tem pedidos",
  "You have submitted no reviews": "Você não realizou nenhum comentário",
  "Mic": "Microfone",
  "Thank you for your order!": "Obrigado pela sua compra!",
  "What can we improve": "Como podemos melhorar",
  "Payment date": "Pagamento",
  "Status": "Situação",
  "View details": "Veja detalhes",
  "For you": "Para você",
  "Services": "Serviços",
  "Payments": "Pagamentos",
  "Secure website": "Site seguro",
  "Work with us": "Trabalhe Conosco",
  "Return and Exchange Policy": "Política de Troca e Devolução",
  "Blog": "Blog",
  "Shipping and Delivery": "Frete e Entrega",
  "Security": "Segurança",
  "Contact": "Contato",
  "Send your Budget": "Envie seu Orçamento",
  "Contracts": "Contratos",
  "My Orders": "Meus Pedidos",
  "2nd copy of the invoice": "2ª via boleto",
  "You are not authorized, please log in.": "Você não está autorizado, faça login.",
  "Write your e-mail:": "Digite o seu e-mail:",
  'I agree to receive emails and communications from Espacial, and I accept the terms and conditions and the privacy policy.': 'Eu concordo em receber emails e comunicações da Espacial, e concordo com os termos e condições de uso e a política de privacidade.',
  'You are at: ': 'Você está em: ',
  "Name": "Nome",
  "Reviews": "Avaliações",
  "Read less": "Ler menos",
  "Read more": "Ler mais",
  "Enter your email": "Digite aqui seu e-mail",
  "Enter your password": "Digite aqui sua senha",
  "Enter your fullname": "Digite aqui seu nome completo",
  "Enter your phone": "Digite aqui seu telefone",
  "Forgot my password": "Esqueceu a senha?",
  "Don't have a registration?": "Não possui cadastro?",
  "All fields are required": "Todos os campos são obrigatórios",
  "Fullname": "Nome Completo",
  "MM/DD/YYYY": "DD/MM/AAAA",
  "Confirm your password": "Confirme sua senha",
  "Log in by entering your email and password.": "Faça login digitando o seu e-mail e senha.",
  "I agree with": "Concordo com a",
  "of": "da",
  "I already have a registration": "Já possuo cadastro",
  "Male": "Masculino",
  "Female": "Feminino",
  "Other": "Outro",
  "Gender": "Gênero",
  "Birthdate": "Data de Nascimento",
  "firstName": "Primeiro Nome",
  "lastName": "Sobrenome",
  "Corporate reason": "Razão Social",
  "Registration Name": "Nome de Registro",
  "You must confirm your account. Please check your email for the confirmation link.": "É necessário que você valide sua conta. Por favor, cheque em seu e-mail o link de confirmação.",
  "Register your email in": "Cadastre-se seu e-mail em nossa",
  "Newsletter": "Lista de Ofertas",
  "See all search results": "Ver todos os resultados de pesquisa",
  "See all": "Ver todos",
  "Checkout": "Finalizar Compra",
  "My Cart": "Meu Carrinho",
  "User Account": "Conta do Usuário",
  "Home page": "Página Inicial",
  "Create an account on the store": "Criar uma conta",
  "Login on the store": "Entrar na sua conta",
  "Select previously saved address": "Selecione seu endereço",
  "Your Account": "Sua Conta",
  "Your Purchase": "Sua Compra",
  "Successful placed order": "Pedido realizado com sucesso",
  "Primary contacts for any questions": "Contatos principais para qualquer dúvida",
  "Send my feedback": "Envie meu feedback",
  "Subject": "Assunto",
  "Write us": "Escreva-nos",
  "Leave us a message and we will return back to you as soon as possible.": "Deixe-nos uma mensagem e retornaremos para você o mais rápido possível.",
  "Talk to us": "Fale conosco",
  "How can we help you today?": 'Em que podemos te ajudar hoje?',
  "The field should have at least 2 characters": "Este campo deve ter pelo menos 2 caracteres",
  "The field should have at least 8 characters": "Este campo deve ter pelo menos 8 caracteres",
  "Clear cart": "Limpar carrinho",
  "Are you sure you would like to clear the shopping cart?": "Você tem certeza que deseja limpar o seu carrinho?",
  "Set as default shipping": "Definir como endereço principal para entrega",
  "Set as default billing": "Definir como dados principais para faturamento",
  "Update the address": "Atualizar endereço",
  "Cart has been cleared": "Carrinho foi limpo",
  "Order Summary": "Resumo do pedido",
  "There are no shipping methods available for this country. We are sorry, please try with a different country.": "",
  "Take advantage of these suggestions based on your selection": "Aproveite estas sugestões baseadas na sua seleção",
  "Explore these unmissable options!": "Explore estas opções imperdíveis!",
  "E-mail has been sent": "E-mail foi enviado",
  "Coupons":"Cupons",
  "Budget": "Orçamento",
  "When sending a quote, you will have access to special conditions that are not available in our standard prices. We are willing to offer exclusive discounts, flexible payment terms and other personalized benefits to meet your specific needs. Take advantage of this opportunity to obtain a tailored proposal that best fits your budget and objectives, thus guaranteeing the best possible conditions.": "Ao enviar o orçamento, poderá ter acesso a condições especiais que não estão disponíveis em nossos preços padrão. Estamos dispostos a oferecer descontos exclusivos, flexibilidade nos prazos de pagamento e outros benefícios personalizados para atender às suas necessidades específicas. Aproveite esta oportunidade para obter uma proposta sob medida que se ajuste melhor ao seu orçamento e aos seus objetivos, garantindo assim as melhores condições possíveis.",
  "Don't miss this chance!": "Não perca essa chance!",
  "Big Brands we work with":"Grandes Marcas que trabalhamos",
  "Product return": "Devolução",
  "Reason": "Motivo",
  "Most searched terms": "Termos mais buscados",
  "Featured Products": "Produtos em destaque",
  "Filter by categorie:": "Filtrar por Categoria:",
  "product(s) found": "produto(s) encontrado(s)",
  "Descendant": "Descendente",
  "Ascendant": "Ascendente",
  "Sort by Price": "Ordenar por Preço",
  "Sort by Name": "Ordenar por Nome ",
  "Sort by Position": "Ordenar por Posição ",
  "Sort by Relevance": "Ordenar por Relevância ",
  "Results for": "Resultados para",
  "Send below your registered email to receive access link to reset password.": "Insira abaixo o email registrado para receber o link de redefinição de senha.",
  "Create Account": "Criar Cadastro Fácil",
  "Coupon applied successfully": "Cupom aplicado com sucesso",
  "Start the session login to apply coupon": "Inicie a sessão de login para aplicar o cupom",
  "Recovery Password": "Recuperar Senha",
  "Confirm Password": "Confirme a senha",
  "An email has been sent to": "Um email foi enviado para",
  "with a token to reset your password.": "com um token para redefinir sua senha.",
  "Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.": "A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, números e caracteres especiais.",
  "Passwords do not match": "As senhas não coincidem",
  "Invalid email": "E-mail inválido",
  "Save Password": "Alterar senha",
  "Token has expired": "O Token Venceu, solicite outro",
  "Copy the token received by email and proceed to reset your password": "Cole o token no campo abaixo para prosseguir com a redefinição da sua senha.",
  "Forgot your password? We\'ll send a token to your email. Just copy it and use it to reset your password.": "Esqueceu sua senha? Enviaremos um token para o seu e-mail. Basta copiá-lo e usá-lo para redefinir sua senha.",
  "Token": "Chave de verificação",
  "Photos": "Fotos",
  "Product code": "Código do produto (SKU)",
  "The deadline for returning the product is 14 days": "O prazo para devolução do produto é de 14 dias",
  "Receive an copy via e-mail": "Receber uma cópia por e-mail",
  "Invalid CNPJ": "CNPJ inválido",
  "Invalid CPF": "CPF inválido",
  "Social Media": "Redes Sociais",
  "error loading posts":"erro ao carregar os posts",
  "Back to list of posts":"Voltar para lista de posts",
  "Loading":"Carregando",
  "You should also see:":"Você também deveria ver:",
  "Message": "Mensagem",
  "Card Number": "Número do cartão",
  "Card Holder": "Proprietário do cartão",
  "CVV": "CVC",
  "Expires":"Vencimento",
  "Expiration Date": "Data de vencimento",
  "Month": "Mês",
  "Year": "Ano",
  'Add new card':"Adicionar novo cartão",
  "Credit cards": "Cartões de crédito",
  "Payment via Pix": "Pagamento via Pix",
  "Scan the QR Code below to make the payment": "Escaneie o QR Code abaixo para realizar o pagamento",
  "Or copy the Pix code below": "Ou copie o Código Pix abaixo",
  "Pix code copied sucessfully": "Código Pix copiado com sucesso",
  "Failed to copy to clipboard": "Falha ao copiar para a área de transferência",
  "Payment via bank slip": "Pagamento via boleto",
  "To make the payment, either copy the line code or scan the barcode": "Para realizar o pagamento, copie a linha digitável ou escaneie o código de barras",
  "Copy line code": "Copiar linha digitável",
  "Download bank slip": "Baixar boleto",
  "You are about to pay a total of": "Você está prestes a pagar um total de",
  "YY": "AA",
  "Are you sure you would like to remove this credit card?": "Você tem certeza que deseja remover este cartão de crédito?",
  "Register and receive our promotions and news in advance": "Cadastre-se e receba nossas promoções e novidades antecipadamente",
  "By signing up, you agree to our policy": "Ao se cadastrar você irá concordar com a nossa",
  "Show on page":"Mostrar",
  "Enter different address": "Inserir endereço diferente",
  "Choose between Legal Entity or Individual and fill in the details.":"Escolha entre Pessoa Jurídica ou Física e preencha os dados.",
  "We do not accept debit or international cards": "Não aceitamos cartões de débito ou internacionais",
  "The minimum order amount is R$100.00, excluding the delivery fee": "O valor mínimo do pedido é de R$100,00, excluindo a taxa de entrega",
  "Take advantage of this opportunity to obtain a tailored proposal that best fits your budget and objectives, thus guaranteeing the best possible conditions.": "Aproveite esta oportunidade para obter uma proposta sob medida que se ajuste melhor ao seu orçamento e aos seus objetivos, garantindo assim as melhores condições possíveis.",
  "When sending a quote, you will have access to special conditions that are not available in our standard prices. We are willing to offer exclusive discounts, flexible payment terms and other personalized benefits to meet your specific needs.": "Ao enviar o orçamento, você poderá ter acesso as condições especiais, desde de descontos exclusivos, flexibilidade nos prazos de pagamento, entre outros benefícios personalizados às suas necessidades.",
  "Products related to budget": "Produtos relacionados para o orçamento",
  "Choose between a legal entity or individual and fill in the details.": "Escolha entre pessoa jurídica ou física e preencha os dados.",
  "Trade name": "Nome Fantasia",
};
