import axios, { AxiosInstance } from 'axios';

export class JetPaymentApiService {
  public AxiosJetPaymentInstance: AxiosInstance;

  constructor() {
    this.AxiosJetPaymentInstance = axios.create({
      baseURL: process.env.JETPAYMENT_API_BASE_URL,
    });
  }
}

export const JetPaymentApi = new JetPaymentApiService().AxiosJetPaymentInstance;
