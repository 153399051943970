


















































































import { SfBottomNavigation, SfCircleIcon } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext, ref, useFetch } from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree } from '~/modules/GraphQL/types';
import SvgImage from '~/components/General/SvgImage.vue';
import MenuDrawer from '~/components/JetUi/organisms/Menu/MenuDrawer.vue';

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    SfCircleIcon,
    MenuDrawer,
    SvgImage,
  },
  setup() {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const categoryTree = ref<CategoryTree[]>([]);
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();

    const getCustomLinks = () => {
      console.warn('Débito técnico 2 - verificar links');
      return [
        { id: '1-link', name: '2° VIA BOLETO', link: 'http://espacial.web22f21.kinghost.net/hom/gestaocontratos.ww_acesso.aspx' },
        { id: '2-link', name: 'CONTRATO', link: 'http://espacial.web22f21.kinghost.net/hom/gestaocontratos.ww_acesso.aspx' },
        { id: '3-link', name: 'ORÇAMENTO', link: '/default/orcamento' },
        { id: '4-link', name: 'CONTATO', link: '/default/fale-conosco' },
        { id: '6-link', name: 'CUPONS', link: '/default/cupons' },
      ];
    };

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);

      categoryTree.value = categoryTree.value.reduce((acc: CategoryTree[], current) => {
        const existing = acc.find(item => item.name === current.name);

        if (existing) {
          if (current.children.length > 0) {
            const index = acc.indexOf(existing);
            acc.splice(index, 1, current);
          }
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

      categoryTree.value.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      console.warn('Débito técnico 1 rever API categorias');
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        toggleLoginModal();
      }
    };

    return {
      categoryTree,
      isAuthenticated,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      handleAccountClick,
      handleHomeClick,
      getCustomLinks
    };
  },
});
