








































































import { SfIcon, SfButton } from '@storefront-ui/vue';

export default {
  name: 'SfNotification',
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'secondary',
      validator(value) {
        return ['secondary', 'info', 'success', 'warning', 'danger'].includes(
          value,
        );
      },
    },
    color: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      default: 'top',
      required: false,
      validator(value) {
        return ['top', 'bottom'].includes(value);
      },
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'added_to_cart';
        case 'danger':
          return 'info_shield';
        default:
          return 'info_circle';
      }
    },
    colorClass() {
      return `color-${this.type}`;
    },
    positionClass() {
      return `position-${this.position}`;
    },
  },
  methods: {
    actionHandler() {
      this.$emit('click:action');
    },
    closeHandler() {
      this.$emit('click:close');
    },
  },
};
