import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c033b64a = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _0a2bd4bf = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _71cf1c8e = () => interopDefault(import('../server/blog/BlogView.vue' /* webpackChunkName: "" */))
const _1f529341 = () => interopDefault(import('../pages/Budget.vue' /* webpackChunkName: "pages/Budget" */))
const _3b6b312a = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _7fe3a511 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _120761a6 = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/Billing.vue' /* webpackChunkName: "" */))
const _2a38df68 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _2847ccec = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/Shipping.vue' /* webpackChunkName: "" */))
const _0ef02063 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _b0f19fd8 = () => interopDefault(import('../modules/checkout/pages/Checkout/JetCheckout/UserAccount.vue' /* webpackChunkName: "" */))
const _0d079406 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _6b343158 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _300eb1c1 = () => interopDefault(import('../pages/Coupons.vue' /* webpackChunkName: "pages/Coupons" */))
const _746678b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0c6adfc4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _7026fc86 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _12bd6dcc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _da3f88c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _6f479e5c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _d3a3f744 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _0f70c36a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _59ee6ad9 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _22a68aa0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _2577a7d5 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _db9cc49a = () => interopDefault(import('../pages/ProductReturn.vue' /* webpackChunkName: "" */))
const _f8cedd6c = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _bdf8bf84 = () => interopDefault(import('../pages/ShippingDelivery.vue' /* webpackChunkName: "" */))
const _1fc98aea = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _47a4acfe = () => interopDefault(import('../pages/ReturnPolicy.vue' /* webpackChunkName: "" */))
const _a3b03fe4 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _355dda91 = () => interopDefault(import('../pages/SearchResultsPage.vue' /* webpackChunkName: "pages/SearchResultsPage" */))
const _fe04d792 = () => interopDefault(import('../pages/post/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/default",
    component: _c033b64a,
    name: "home___default"
  }, {
    path: "/en",
    component: _c033b64a,
    name: "home___en"
  }, {
    path: "/german",
    component: _c033b64a,
    name: "home___german"
  }, {
    path: "/default/AboutUs",
    component: _0a2bd4bf,
    name: "AboutUs___default"
  }, {
    path: "/default/blog",
    component: _71cf1c8e
  }, {
    path: "/default/Budget",
    component: _1f529341,
    name: "Budget___default"
  }, {
    path: "/default/cart",
    component: _3b6b312a,
    name: "cart___default"
  }, {
    path: "/default/checkout",
    component: _7fe3a511,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _120761a6,
      name: "billing___default"
    }, {
      path: "payment",
      component: _2a38df68,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _2847ccec,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _0ef02063,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _b0f19fd8,
      name: "user-account___default"
    }]
  }, {
    path: "/default/Cms",
    component: _0d079406,
    name: "Cms___default"
  }, {
    path: "/default/Contact",
    component: _6b343158,
    name: "Contact___default"
  }, {
    path: "/default/Coupons",
    component: _300eb1c1,
    name: "Coupons___default"
  }, {
    path: "/default/cupons",
    component: _300eb1c1,
    name: "cupons___default"
  }, {
    path: "/default/customer",
    component: _746678b0,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _0c6adfc4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _7026fc86,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _12bd6dcc,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _da3f88c4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _6f479e5c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _d3a3f744,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _0f70c36a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/default/reset-password",
      component: _59ee6ad9,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _22a68aa0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _2577a7d5,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/devolucao",
    component: _db9cc49a,
    name: "devolução___default"
  }, {
    path: "/default/fale-conosco",
    component: _6b343158,
    name: "contato___default"
  }, {
    path: "/default/Faq",
    component: _f8cedd6c,
    name: "Faq___default"
  }, {
    path: "/default/frete-entrega",
    component: _bdf8bf84,
    name: "frete e entrega___default"
  }, {
    path: "/default/Home",
    component: _c033b64a,
    name: "Home___default"
  }, {
    path: "/default/orcamento",
    component: _1f529341,
    name: "orçamento___default"
  }, {
    path: "/default/Page",
    component: _1fc98aea,
    name: "Page___default"
  }, {
    path: "/default/politica-devolucao",
    component: _47a4acfe,
    name: "politica de troca e devolução___default"
  }, {
    path: "/default/privacidade",
    component: _a3b03fe4,
    name: "politica de privacidade___default"
  }, {
    path: "/default/PrivacyPolicy",
    component: _a3b03fe4,
    name: "PrivacyPolicy___default"
  }, {
    path: "/default/ProductReturn",
    component: _db9cc49a,
    name: "ProductReturn___default"
  }, {
    path: "/default/ReturnPolicy",
    component: _47a4acfe,
    name: "ReturnPolicy___default"
  }, {
    path: "/default/SearchResultsPage",
    component: _355dda91,
    name: "SearchResultsPage___default"
  }, {
    path: "/default/ShippingDelivery",
    component: _bdf8bf84,
    name: "ShippingDelivery___default"
  }, {
    path: "/default/sobre-nos",
    component: _0a2bd4bf,
    name: "sobre nós___default"
  }, {
    path: "/en/AboutUs",
    component: _0a2bd4bf,
    name: "AboutUs___en"
  }, {
    path: "/en/blog",
    component: _71cf1c8e
  }, {
    path: "/en/Budget",
    component: _1f529341,
    name: "Budget___en"
  }, {
    path: "/en/cart",
    component: _3b6b312a,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _7fe3a511,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _120761a6,
      name: "billing___en"
    }, {
      path: "payment",
      component: _2a38df68,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _2847ccec,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _0ef02063,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _b0f19fd8,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _0d079406,
    name: "Cms___en"
  }, {
    path: "/en/Contact",
    component: _6b343158,
    name: "Contact___en"
  }, {
    path: "/en/Coupons",
    component: _300eb1c1,
    name: "Coupons___en"
  }, {
    path: "/en/cupons",
    component: _300eb1c1,
    name: "cupons___en"
  }, {
    path: "/en/customer",
    component: _746678b0,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _0c6adfc4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _7026fc86,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _12bd6dcc,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _da3f88c4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _6f479e5c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _d3a3f744,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _0f70c36a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _59ee6ad9,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _22a68aa0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _2577a7d5,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/devolucao",
    component: _db9cc49a,
    name: "devolução___en"
  }, {
    path: "/en/fale-conosco",
    component: _6b343158,
    name: "contato___en"
  }, {
    path: "/en/Faq",
    component: _f8cedd6c,
    name: "Faq___en"
  }, {
    path: "/en/frete-entrega",
    component: _bdf8bf84,
    name: "frete e entrega___en"
  }, {
    path: "/en/Home",
    component: _c033b64a,
    name: "Home___en"
  }, {
    path: "/en/orcamento",
    component: _1f529341,
    name: "orçamento___en"
  }, {
    path: "/en/Page",
    component: _1fc98aea,
    name: "Page___en"
  }, {
    path: "/en/politica-devolucao",
    component: _47a4acfe,
    name: "politica de troca e devolução___en"
  }, {
    path: "/en/privacidade",
    component: _a3b03fe4,
    name: "politica de privacidade___en"
  }, {
    path: "/en/PrivacyPolicy",
    component: _a3b03fe4,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/ProductReturn",
    component: _db9cc49a,
    name: "ProductReturn___en"
  }, {
    path: "/en/ReturnPolicy",
    component: _47a4acfe,
    name: "ReturnPolicy___en"
  }, {
    path: "/en/SearchResultsPage",
    component: _355dda91,
    name: "SearchResultsPage___en"
  }, {
    path: "/en/ShippingDelivery",
    component: _bdf8bf84,
    name: "ShippingDelivery___en"
  }, {
    path: "/en/sobre-nos",
    component: _0a2bd4bf,
    name: "sobre nós___en"
  }, {
    path: "/german/AboutUs",
    component: _0a2bd4bf,
    name: "AboutUs___german"
  }, {
    path: "/german/blog",
    component: _71cf1c8e
  }, {
    path: "/german/Budget",
    component: _1f529341,
    name: "Budget___german"
  }, {
    path: "/german/cart",
    component: _3b6b312a,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _7fe3a511,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _120761a6,
      name: "billing___german"
    }, {
      path: "payment",
      component: _2a38df68,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _2847ccec,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _0ef02063,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _b0f19fd8,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _0d079406,
    name: "Cms___german"
  }, {
    path: "/german/Contact",
    component: _6b343158,
    name: "Contact___german"
  }, {
    path: "/german/Coupons",
    component: _300eb1c1,
    name: "Coupons___german"
  }, {
    path: "/german/cupons",
    component: _300eb1c1,
    name: "cupons___german"
  }, {
    path: "/german/customer",
    component: _746678b0,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _0c6adfc4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _7026fc86,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _12bd6dcc,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _da3f88c4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _6f479e5c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _d3a3f744,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _0f70c36a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _59ee6ad9,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _22a68aa0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _2577a7d5,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/devolucao",
    component: _db9cc49a,
    name: "devolução___german"
  }, {
    path: "/german/fale-conosco",
    component: _6b343158,
    name: "contato___german"
  }, {
    path: "/german/Faq",
    component: _f8cedd6c,
    name: "Faq___german"
  }, {
    path: "/german/frete-entrega",
    component: _bdf8bf84,
    name: "frete e entrega___german"
  }, {
    path: "/german/Home",
    component: _c033b64a,
    name: "Home___german"
  }, {
    path: "/german/orcamento",
    component: _1f529341,
    name: "orçamento___german"
  }, {
    path: "/german/Page",
    component: _1fc98aea,
    name: "Page___german"
  }, {
    path: "/german/politica-devolucao",
    component: _47a4acfe,
    name: "politica de troca e devolução___german"
  }, {
    path: "/german/privacidade",
    component: _a3b03fe4,
    name: "politica de privacidade___german"
  }, {
    path: "/german/PrivacyPolicy",
    component: _a3b03fe4,
    name: "PrivacyPolicy___german"
  }, {
    path: "/german/ProductReturn",
    component: _db9cc49a,
    name: "ProductReturn___german"
  }, {
    path: "/german/ReturnPolicy",
    component: _47a4acfe,
    name: "ReturnPolicy___german"
  }, {
    path: "/german/SearchResultsPage",
    component: _355dda91,
    name: "SearchResultsPage___german"
  }, {
    path: "/german/ShippingDelivery",
    component: _bdf8bf84,
    name: "ShippingDelivery___german"
  }, {
    path: "/german/sobre-nos",
    component: _0a2bd4bf,
    name: "sobre nós___german"
  }, {
    path: "/default/blog/post/:slug",
    component: _fe04d792
  }, {
    path: "/en/blog/post/:slug",
    component: _fe04d792
  }, {
    path: "/german/blog/post/:slug",
    component: _fe04d792
  }, {
    path: "/default/post/:slug?",
    component: _fe04d792,
    name: "post-slug___default"
  }, {
    path: "/default/search/:query",
    component: _355dda91,
    name: "search-results___default"
  }, {
    path: "/en/post/:slug?",
    component: _fe04d792,
    name: "post-slug___en"
  }, {
    path: "/en/search/:query",
    component: _355dda91,
    name: "search-results___en"
  }, {
    path: "/german/post/:slug?",
    component: _fe04d792,
    name: "post-slug___german"
  }, {
    path: "/german/search/:query",
    component: _355dda91,
    name: "search-results___german"
  }, {
    path: "/default/:slug+",
    component: _1fc98aea,
    name: "page___default"
  }, {
    path: "/en/:slug+",
    component: _1fc98aea,
    name: "page___en"
  }, {
    path: "/german/:slug+",
    component: _1fc98aea,
    name: "page___german"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
