

































import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute, defineComponent, ref, onMounted, onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import HeaderButton from '~/components/JetUi/atoms/FloatButtons/HeaderButton.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/JetUi/atoms/topbar/TopBar.vue';
import FloatWppButton from '~/components/JetUi/atoms/FloatButtons/FloatWppButton.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    FloatWppButton,
    HeaderButton,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/JetLogin/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const header = ref(null);
    const headerHeight = ref(0);
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    const updateHeaderHeight = () => {
      if (header.value) {
        headerHeight.value = header.value.offsetHeight;
      }
    };

    onMounted(() => {
      updateHeaderHeight();
      window.addEventListener('resize', updateHeaderHeight);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateHeaderHeight);
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      header,
      headerHeight,
    };
  },
  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
