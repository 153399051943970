


































import {
  defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';
import { SfButton, SfIcon } from '@storefront-ui/vue';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';
import HeaderNavigationItem from '../../../Header/Navigation/HeaderNavigationItem.vue';
import HeaderNavigationSubcategories from '~/components/JetUi/organisms/Header/HeaderNavigationSubcategories.vue';
import MenuDrawer from '~/components/JetUi/organisms/Menu/MenuDrawer.vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationSubcategories,
    SfButton,
    SfIcon,
    HeaderNavigationItem,
    MenuDrawer
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();
    const currentCategory = ref<CategoryTree | null>(null);
    let lvl0CatFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;

    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    const isDrawerOpen = ref(false);

    const openDrawer = () => {
      isDrawerOpen.value = true;
    };

    const closeDrawer = () => {
      isDrawerOpen.value = false;
    };

    const hasChildren = (category: CategoryTree) => Boolean(category?.children?.length);

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    }

    const getImportantLinks = (categories: CategoryTree[]) => {
      return categories.filter(category => ['COFFEE BREAK', 'ESCRITÓRIO', 'INFORMÁTICA',  'PAPELARIA','LIMPEZA' ].includes(category.name))
    }

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };

    const getCustomLinks = () => {
      console.warn('Débito técnico 2 - verificar links');
      return [
        { id: '1-link', name: '2° VIA BOLETO', link: 'http://espacial.web22f21.kinghost.net/hom/gestaocontratos.ww_acesso.aspx' },
        { id: '2-link', name: 'CONTRATO', link: 'http://espacial.web22f21.kinghost.net/hom/gestaocontratos.ww_acesso.aspx' },
        { id: '3-link', name: 'ORÇAMENTO', link: '/default/orcamento' },
        { id: '4-link', name: 'CONTATO', link: '/default/fale-conosco' },
        { id: '5-link', name: 'BLOG', link: '/default/blog' },
        { id: '6-link', name: 'CUPONS', link: '/default/cupons' },

      ];
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    return {
      navRight,
      navLeft,
      currentCategory,
      setCurrentCategory,
      getCatLink,
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      hasChildren,
      hasFocus,
      getCustomLinks,
      getImportantLinks,
      onMouseEnter,
      setFocus,
      focusRootElementAndHideSubcategories
    };
  },
});
