import axios, { AxiosInstance } from 'axios';

export class ItauApiService {
  public AxiosBoletoInstance: AxiosInstance;

  public AxiosPixInstance: AxiosInstance;

  constructor() {
    const headers = {
      'x-itau-apikey': process.env.CLIENT_ID,
      'x-itau-correlationID': process.env.CLIENT_ID,
      'x-itau-flowID': process.env.CLIENT_ID,
      Authorization: process.env.ITAU_API_TOKEN,
    };

    this.AxiosBoletoInstance = axios.create({
      baseURL: process.env.ITAU_API_BOLETO_BASE_URL,
      headers: {
        ...headers,
      },
    });

    this.AxiosPixInstance = axios.create({
      baseURL: process.env.ITAU_API_PIX_BASE_URL,
      headers,
    });
  }
}

export const itauApiBoleto = new ItauApiService().AxiosBoletoInstance;
export const itauApiPix = new ItauApiService().AxiosPixInstance;
