import { ComputedRef } from '@nuxtjs/composition-api';
import { Customer } from '~/composables';
import { ItauBoletoRequest } from '~/server/payments/types/BoletoTypes';

const formatDate = (date: Date): string => date.toISOString().split('T')[0];

const formatValue = (value: number): string => {
  const formattedValue = value.toFixed(2);
  return formattedValue.replace('.', '');
};

const BuildSlipBody = (user: ComputedRef<Customer>, value: number, tipoPessoa: string, data: Date, dataVencimento: Date): ItauBoletoRequest => {
  const endereco = user.value.addresses[0];

  return {
    etapa_processo_boleto: 'simulacao',
    beneficiario: {
      id_beneficiario: '005600753502',
    },
    dado_boleto: {
      pagador: {
        pessoa: {
          nome_pessoa: `${user.value.firstname} ${user.value.lastname}`,
          tipo_pessoa: {
            codigo_tipo_pessoa: tipoPessoa,
            ...(tipoPessoa === 'F' && { numero_cadastro_pessoa_fisica: user.value.taxvat.replace(/\D/g, '') }),
            ...(tipoPessoa === 'J' && { numero_cadastro_nacional_pessoa_juridica: user.value.taxvat.replace(/\D/g, '') }),
          },
        },
        endereco: {
          nome_logradouro: `${endereco.street[0]}, ${endereco.street[1]}`,
          nome_cidade: endereco.city,
          sigla_UF: endereco.region.region_code,
          numero_CEP: endereco.postcode,
        },
      },
      tipo_boleto: 'a vista',
      codigo_carteira: '109', // VERIFICAR JUNTO A ESPACIAL OU ANALISTA ITAU
      codigo_especie: '01',
      valor_total_titulo: formatValue(value),
      data_emissao: formatDate(data),
      dados_individuais_boleto: [
        {
          numero_nosso_numero: '12345678', // NUMERO UNICO DE 8 DIGITOS
          data_vencimento: formatDate(dataVencimento),
          valor_titulo: formatValue(value),
          data_limite_pagamento: formatDate(dataVencimento),
        },
      ],
      lista_mensagem_cobranca: [],
    },
  };
};

export default BuildSlipBody;
