import { readonly, ref, useContext } from "@nuxtjs/composition-api";
import useCart from "~/modules/checkout/composables/useCart";
import type { UseMakeOrderErrors, UseMakeOrderInterface } from "./useMakeOrder";
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';

export function useMakeOrder(): UseMakeOrderInterface {
  const VUE_APP_ORDER_SERVICE = process.env.VUE_APP_ORDER_SERVICE || "http://127.0.0.1:3014";
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart } = useCart();
  const context = useContext();
  const { load: loadUserBilling } = useUserAddress();

  const definirMetodoPagamento = async (BEARER_TOKEN: string, code: string) => {
    try {
      const apiUrl = `${VUE_APP_ORDER_SERVICE}/order/payment-method`;
      return await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          code: code,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const definirFormaEntrega = async (BEARER_TOKEN: string) => {
    try {
      const selectedShippingMethod = cart.value.shipping_addresses[0].selected_shipping_method;
      const apiUrl = `${VUE_APP_ORDER_SERVICE}/order/shipping-method`;
      return await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          carrier_code: selectedShippingMethod.carrier_code,
          method_code: selectedShippingMethod.method_code,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const definirEnderecoFaturamento = async (BEARER_TOKEN: string) => {
    try {
      if (cart.value.billing_address) return

      const loadedUserBilling = await loadUserBilling();
      const [defaultAddress = null] = userBillingGetters.getAddresses(loadedUserBilling, { default_billing: true });
      const apiUrl = `${VUE_APP_ORDER_SERVICE}/order/billing-address`;
      return await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          billing_address: {
            address: {
              firstname: defaultAddress.firstname,
              lastname: defaultAddress.lastname,
              company: defaultAddress.company,
              street: defaultAddress.street,
              city: defaultAddress.city,
              region: defaultAddress.region.region_code,
              region_id: defaultAddress.region.region_id,
              postcode: defaultAddress.postcode,
              country_code: defaultAddress.country_code,
              telephone: defaultAddress.telephone,
              save_in_address_book: true
            }
          },
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const definirEnderecoEntrega = async (BEARER_TOKEN: string) => {
    try {
      if (cart.value.shipping_addresses.length) return;

      const shippingAddresses = cart.value.shipping_addresses.map((address) => {
        return {
          address: {
            firstname: address.firstname,
            lastname: address.lastname,
            street: address.street,
            city: address.city,
            company: address.company,
            region: address.region.code,
            region_id: address.region.region_id,
            postcode: address.postcode,
            telephone: address.telephone,
            country_code: address.country.code,
          },
        };
      });

      const apiUrl = `${VUE_APP_ORDER_SERVICE}/order/shipping-address`;
      return await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
          shipping_addresses: shippingAddresses,
        }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const criarPedido = async (BEARER_TOKEN: string) => {
    try {
      const apiUrl = `${VUE_APP_ORDER_SERVICE}/order/create`;
      const response =  await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${BEARER_TOKEN}`,
        },
        body: JSON.stringify({
          cart_id: cart.value.id,
        }),
      });

      return response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const make = async (code: string): Promise<any> => {
    const BEARER_TOKEN = context.$vsf.$magento.config.state.getCustomerToken();

    await Promise.all([
      definirMetodoPagamento(BEARER_TOKEN, code),
      definirFormaEntrega(BEARER_TOKEN),
      definirEnderecoFaturamento(BEARER_TOKEN),
      definirEnderecoEntrega(BEARER_TOKEN),
    ]);

    return await criarPedido(BEARER_TOKEN);
  };

  return { make, error: readonly(error), loading: readonly(loading) };
}

export * from "./useMakeOrder";
export default useMakeOrder;
