


































import SvgImage from '~/components/General/SvgImage.vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FloatingWhatsappIcon',
  components: {
    SvgImage,
  },
  setup() {
    const isMenuOpen = ref(false);

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const openLink = (link: string) => {
      console.warn('Débito técnico - alterar links para redirecionamento do whatsapp');

      window.open(link, '_blank');
      isMenuOpen.value = false;
    };

    return {
      isMenuOpen,
      toggleMenu,
      openLink,
    };
  },
});
