import { ItauBoletoResponse, JetServiceSlipBody } from '~/server/payments/types/BoletoTypes';

export default function SlipTransform(itauSlip: ItauBoletoResponse): JetServiceSlipBody {
  const individualBoleto = itauSlip.dado_boleto.dados_individuais_boleto[0];

  const boletoSDTObject = {
    banconumero: '341',
    banconome: 'BANCO ITAU NUMERO BANCO 341',
    bancoapelido: '',
    bancousodobanco: '',
    bancocodigocarteira400: itauSlip.dado_boleto.codigo_carteira, // Valor do código do Itaú
    bancocodigoragencia400: 56, // Valor do Itaú
    bancocodigorconta400: 75_350, // Valor do Itaú
    bancocontardigito400: '2', // Valor do Itaú
    bancoagenciardigito400: '',
    banconumerocontrato400: 75_350,
    bancotipoconvenio400: '4',
    bancocodigocedente400: '',
    bancobasecontacosmo400: '',
    bancoindicecontacosmo400: '4',
    bancosequeciacontacosmo400: '',
    bancotipocarteira: '00',
    bancosubstituirinstrucaomulta: '',
    notafiscalnumero: '',
    notafiscalnumeroagrupamento: '',
    beneficiarionome: itauSlip.beneficiario.nome_cobranca,
    beneficiariocpfcnpj: itauSlip.beneficiario.tipo_pessoa.codigo_tipo_pessoa === 'J' ? itauSlip.beneficiario.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica : itauSlip.beneficiario.tipo_pessoa.numero_cadastro_pessoa_fisica,
    beneficiarioendereco: itauSlip.beneficiario.endereco.nome_logradouro.split(',')[0],
    beneficiarioendereconumero: itauSlip.beneficiario.endereco.nome_logradouro.split(',')[1],
    beneficiarioenderecocomple: '',
    beneficiariocep: itauSlip.beneficiario.endereco.numero_CEP,
    beneficiariobairro: itauSlip.beneficiario.endereco.nome_bairro,
    beneficiariocidade: itauSlip.beneficiario.endereco.nome_cidade,
    beneficiarioestado: itauSlip.beneficiario.endereco.sigla_UF,
    boletoespeciedocumento: 'DM',
    boletodataemissao: itauSlip.dado_boleto.data_emissao,
    boletodatavencimento: individualBoleto.data_vencimento,
    boletovalor: (Number(individualBoleto.valor_titulo) / 100).toFixed(2),
    boletonossonumero: individualBoleto.numero_nosso_numero,

    boletonumerodocumento: individualBoleto.numero_nosso_numero, // VERIFICAR

    BoletoNossoNumeroDigito: individualBoleto.numero_nosso_numero,
    boletoinstrucao1: individualBoleto?.lista_mensagens_cobranca[0],
    boletoinstrucao2: individualBoleto?.lista_mensagens_cobranca[1],
    boletoinstrucao3: individualBoleto?.lista_mensagens_cobranca[2],
    boletoinstrucao4: individualBoleto?.lista_mensagens_cobranca[3],
    boletoinstrucao5: individualBoleto?.lista_mensagens_cobranca[4],
    clientecodigo: 40_578,
    clientecpfcnpj: itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.codigo_tipo_pessoa === 'J' ? itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica : itauSlip.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_pessoa_fisica,
    clientenome: itauSlip.dado_boleto.pagador.pessoa.nome_pessoa,
    clientecep: itauSlip.dado_boleto.pagador.endereco.numero_CEP,
    clienteendereco: itauSlip.dado_boleto.pagador.endereco.nome_logradouro.split(',')[0],
    clienteendereconumero: itauSlip.dado_boleto.pagador.endereco.nome_logradouro.split(',')[1],
    clientebairro: itauSlip.dado_boleto.pagador.endereco.nome_bairro,
    clientecidade: itauSlip.dado_boleto.pagador.endereco.nome_cidade,
    clienteuf: itauSlip.dado_boleto.pagador.endereco.sigla_UF,
    parametroboletomulta: 0,
    parametroimprimirenderecobeneficiario: '',
    parametroobsboleto: '',
    parametroalteralocalpagamento: '',
  };
  return {
    BoletoSDTJSON: JSON.stringify(boletoSDTObject),
  };
}
