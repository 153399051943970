import { ItauBoletoRequest, ItauBoletoQueryRequest, ItauBoletoResponse } from './types/BoletoTypes';
import { itauApiBoleto } from './ItauApiInstance';
import { JetPaymentApi } from './JetPaymentApiInstance';

class BoletoService {
  // Emissão de boleto
  async emitirBoleto(data: ItauBoletoRequest): Promise<ItauBoletoResponse> {
    try {
      const response = await JetPaymentApi.post('itau/boleto/transactions', data);
      return response.data.data;
    } catch (error) {
      throw new Error(`Erro ao emitir Boleto: ${error}`);
    }
  }

  // consultar status de pagamento
  async recuperarBoleto(data: ItauBoletoQueryRequest): Promise<any> {
    try {
      const queryString = this.createQueryString(data);
      const response = await itauApiBoleto.get(`/boletos?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao consultar boleto: ${error}`);
    }
  }

  createQueryString = (data: ItauBoletoQueryRequest): string => {
    const queryObject: Record<string, string> = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        queryObject[key] = value.toString();
      }
    });
    return new URLSearchParams(queryObject).toString();
  };
}

export default new BoletoService();
